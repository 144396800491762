import React from 'react'
import './NewFeedBackCSS.css'
import { useState , useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useFeedbackContext } from './FeedbackContext';

export const HotelFeedBackJS = () => {

    useEffect(() => {
        document.title = "Hotel-Feedback";
      }, []);

    const { feedbackData, updateFeedbackData,apiData_API, updateApiData } = useFeedbackContext();

const [Any_other_Suggestions , SetSuggestions] = useState("");
console.log(Any_other_Suggestions);
    const TextInput = {
        Any_other_Suggestions,FinalSubmit: true,FeedbackEmailRowId: feedbackData.Row_Id
    }
    const notify = (val) => toast.warn(val, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,    
        progress: undefined,
        theme: 'colored'
        });
    const [currentStep, setCurrentStep] = useState(1);
    const [isSubmitted, setIsSubmitted] = useState(false);
// console.log(notify);
    const [ratings, setRatings] = useState({
        Safety: 0,
        Hygiene_Cleanliness: 0,
        Value_for_Money: 0,
        Quality_of_Food: 0,
        Room_Amenities_WiFi: 0,
        Courteous_staff: 0,
        Rate_HB_Service: 0
    });
    
    useEffect(() => {
        if (apiData_API.Data && apiData_API.Data.Msg === "Continue") {
            const initialRatings = {
                Safety: apiData_API.Data.Safety || 0,
                Hygiene_Cleanliness: apiData_API.Data.General_Cleanliness || 0,
                Value_for_Money: apiData_API.Data.Value_for_Money || 0,
                Quality_of_Food: apiData_API.Data.Quality_of_Food || 0,
                Room_Amenities_WiFi: apiData_API.Data.Room_Amenities_WiFi || 0,
                Courteous_staff: apiData_API.Data.Courteous_staff || 0,
                Rate_HB_Service: apiData_API.Data.Rate_HB_Service || 0,
            };
            setRatings(initialRatings);
            SetSuggestions(apiData_API.Data.Any_other_Suggestions || "");

            // Determine the initial step based on completed ratings
            if (initialRatings.Rate_HB_Service) {
                setCurrentStep(3);
            } else if (initialRatings.Quality_of_Food || initialRatings.Room_Amenities_WiFi || initialRatings.Courteous_staff) {
                setCurrentStep(2);
            } else if (initialRatings.Safety || initialRatings.Hygiene_Cleanliness || initialRatings.Value_for_Money) {
                setCurrentStep(1);
            } else {
                setCurrentStep(1);
            }
        }
    }, [apiData_API]);

    useEffect(() => {
        if (apiData_API.Data && apiData_API.Data.Msg === "Continue") {
            const initialRatings = {
                Safety: apiData_API.Data.Safety || 0,
                Hygiene_Cleanliness: apiData_API.Data.General_Cleanliness || 0,
                Value_for_Money: apiData_API.Data.Value_for_Money || 0,
                Quality_of_Food: apiData_API.Data.Quality_of_Food || 0,
                Room_Amenities_WiFi: apiData_API.Data.Room_Amenities_WiFi || 0,
                Courteous_staff: apiData_API.Data.Courteous_staff || 0,
                Rate_HB_Service: apiData_API.Data.Rate_HB_Service || 0,
            };
            setRatings(initialRatings);
            SetSuggestions(apiData_API.Data.Any_other_Suggestions || "");

            // Determine the initial step based on completed ratings
            if (initialRatings.Rate_HB_Service) {
                setCurrentStep(3);
            } else if (initialRatings.Quality_of_Food || initialRatings.Room_Amenities_WiFi || initialRatings.Courteous_staff) {
                setCurrentStep(2);
            } else if (initialRatings.Safety || initialRatings.Hygiene_Cleanliness || initialRatings.Value_for_Money) {
                setCurrentStep(1);
            } 
            else if (initialRatings.Safety === 0){
                setCurrentStep(0);
            }
            else {
                setCurrentStep(1);
            }
        }
          else{
            setCurrentStep(0);
          }
     
      }, []);

    const totalSteps = 3;

    const nextStep = () => {
        // Check if all ratings are filled before moving to the next step
        if (currentStep === 1 && (ratings.Safety === 0 || ratings.Hygiene_Cleanliness === 0 || ratings.Value_for_Money === 0 )) {
            console.log("")
            notify('Please rate all fields before moving to the next step.');
            return;
        }

        if (currentStep === 2 && (ratings.Quality_of_Food === 0 || ratings.Room_Amenities_WiFi === 0 || ratings.Courteous_staff === 0)) {
            notify('Please rate all fields before moving to the next step.');
            return;
        }

        if (currentStep === 3 && (ratings.Rate_HB_Service === 0)) {
            notify('Please rate all fields before moving to the next step.');
            return;
        }

        setCurrentStep(prev => Math.min(prev + 1, totalSteps));
    };

    const previousStep = () => {
        // Check if all ratings are filled before moving to the previous step
        // if (currentStep === 2 && (ratings.rating4 === 0|| ratings.rating5 === 0)) {
        //     notify('Please rate all fields before moving to the previous step.');
        //     return;
        // }

        // if (currentStep === 3 && ( ratings.rating6 === 0|| ratings.rating7 === 0|| ratings.rating8 === 0)) {
        //     notify('Please rate all fields before moving to the previous step.');
        //     return;
        // }

        // if (currentStep === 4 && ( ratings.rating9 === 0)) {
        //     notify('Please rate all fields before moving to the previous step.');
        //     return;
        // }

        setCurrentStep(prev => Math.max(prev - 1, 0));
    };

    const handleSubmit = () => {
        // Check if all ratings are filled
        for (let key in ratings) {
            if (ratings[key] === 0 && ratings.Any_other_Suggestions!=0) {
                notify('Please rate all fields before submitting.');
                return;
            }
        }
        setIsSubmitted(true);
        // Call your API with the final ratings data
        axios.post(
             'https://warsoftapibookingtest.staysimplyfied.com/API/FeedbackReport/UpdateFeedBackDetails'
            // 'https://warsoftv3bookingapi.warsoft.in/API/FeedbackReport/UpdateFeedBackDetails'
            , TextInput)
            .then(response => {
                console.log('Feedback submitted:', response.data);
            })
            .catch(error => {
                console.error('There was an error submitting the feedback:', error);
            });
    };

    const handleRatingChange = (e) => {
        const { name, value } = e.target;
        const numericValue = parseInt(value);
        setRatings(prevRatings => ({
            ...prevRatings,
            [name]: numericValue
        }));

        // Call API for each star click
        axios.post(
             'https://warsoftapibookingtest.staysimplyfied.com/API/FeedbackReport/UpdateFeedBackDetails',
            // 'https://warsoftv3bookingapi.warsoft.in/API/FeedbackReport/UpdateFeedBackDetails',
             { [name]: numericValue,FeedbackEmailRowId: feedbackData.Row_Id })
            .then(response => {
                console.log('Rating updated:', response.data);
            })
            .catch(error => {
                console.error('There was an error updating the rating:', error);
            });
    };

    const renderStarRating = (ratingName) => {
        return (
            <div className="star-rating">
                {[...Array(5)].map((_, i) => (
                    <React.Fragment key={`${ratingName}-${i + 1}`}>
                        <input type="radio" id={`${ratingName}-${i + 1}`} name={ratingName} value={5 - i} onChange={handleRatingChange} checked={ratings[ratingName] === 5 - i} />
                        <label htmlFor={`${ratingName}-${i + 1}`} className="star">&#9733;</label>
                    </React.Fragment>
                ))}
            </div>
        );
    };

    return (
        <div>
            <ToastContainer />
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <div className="col-11 col-sm-10">
                        <div className="card px-0 pt-4 pb-0 mt-3 mb-3">
                            <div className="heading">
                                <img src="https://portalvhds4prl9ymlwxnt8.blob.core.windows.net/img/HBD_logo.png" alt="Humming Bird" />
                            </div>
                            <form id="msform">
                                {!isSubmitted ? (
                                    <>
                                     {currentStep === 0 && (
                                <fieldset>
                                    <div class="form-card">
                                    <h2 className="headerdetailsCSS">Booking Details</h2>
                                    <table className="form-table">
                                    <tr>
                                            <th className="table_align">HB Booking ID </th>
                                            <th></th>
                                            <th className="colan_th">:</th>
                                            <th></th>
                                            <td className="table_right">{apiData_API.Data.BookingCode}</td>
                                        </tr>
                                        <tr>
                                            <th className="table_align">Guest Name </th>
                                            <th></th>
                                            <th className="colan_th">:</th>
                                            <th></th>
                                            <td className="table_right">{apiData_API.Data.GuestName}</td>
                                        </tr>
                                    
                                        <tr>
                                            <th className="table_align">Property Name </th>
                                            <th></th>
                                            <th className="colan_th">:</th>
                                            <th></th>
                                            <td className="table_right">{apiData_API.Data.PropertyName}</td>
                                        </tr>
                                        <tr>
                                            <th className="table_align">Check-In-Date </th>
                                            <th></th>
                                            <th className="colan_th">:</th>
                                            <th></th>
                                            <td className="table_right">{apiData_API.Data.CheckInDate}</td>
                                        </tr>
                                        <tr>
                                            <th className="table_align">Check-Out-Date </th>
                                            <th></th>
                                            <th className="colan_th">:</th>
                                            <th></th>
                                            <td className="table_right">{apiData_API.Data.CheckOutDate}</td>
                                        </tr>
                                    </table>
                                    </div>
                                    
                                    <div className="pageindicator">0/3</div>
                                    <input
                                    type="button"
                                    name="Start"
                                    className="next action-button"
                                    value={
                                        apiData_API && apiData_API.Data.Msg === "Continue"
                                        ? "Resume"
                                        : "Start"
                                    }
                                    onClick={nextStep}
                                    />
                                </fieldset>
                    )}
                                        {currentStep === 1 && (
                                            <fieldset>
                                                <div className="form-card">
                                                    <div className="margdiv">
                                                        <label className="main-head">Safety</label>
                                                        {renderStarRating('Safety')}
                                                    </div>
                                                    <div className="margdiv">
                                                        <label className="main-head">Hygiene / Cleanliness</label>
                                                        {renderStarRating('Hygiene_Cleanliness')}
                                                    </div>
                                                    <div className="margdiv">
                                                        <label className="main-head">Value for Money</label>
                                                        {renderStarRating('Value_for_Money')}
                                                    </div>
                                                </div>
                                                       <div className="pageindicator">1/3</div>
                                                <input type="button" name="next" className="next action-button" value="Next" onClick={nextStep} />
                                                <input type="button" name="previous" className="previous action-button-previous" value="Previous" onClick={previousStep} />
                                            </fieldset>
                                        )}
                                        {currentStep === 2 && (
                                            <fieldset>
                                                <div className="form-card">
                                                    <div className="margdiv">
                                                        <label className="main-head">Quality of Food</label>
                                                        {renderStarRating('Quality_of_Food')}
                                                    </div>
                                                    <div className="margdiv">
                                                        <label className="main-head">Room Amenities WiFi</label>
                                                        {renderStarRating('Room_Amenities_WiFi')}
                                                    </div>
                                                    <div className="margdiv">
                                                        <label className="main-head">Courteous staff</label>
                                                        {renderStarRating('Courteous_staff')}
                                                    </div>
                                                </div>
                                                <div className="pageindicator">2/3 </div>
                                                <input type="button" name="next" className="next action-button" value="Next" onClick={nextStep} />
                                                <input type="button" name="previous" className="previous action-button-previous" value="Previous" onClick={previousStep} />
                                            </fieldset>
                                        )}
                                        {currentStep === 3 && (
                                            <fieldset>
                                            <div className="form-card">
                                                <div className="margdiv">
                                                    <label className="main-head">Rate HB Service</label>
                                                    {renderStarRating('Rate_HB_Service')}
                                                </div>
                                                <div className="margdiv">
                                                    <label className="main-head">Any Other Suggestions</label>
                                                    <span>
                                                        <textarea className='textboxsize' type="text" placeholder="Any Other Suggestions" onChange={(e)=>SetSuggestions(e.target.value)} ></textarea>
                                                    </span>
                                                    
                                                </div>
                                            </div>
                                            <div className="pageindicator">3/3</div>
                                            <input type="button" name="submit" className="next action-button" value="Submit" onClick={handleSubmit} />
                                            
                                            <input type="button" name="previous" className="previous action-button-previous" value="Previous" onClick={previousStep} />
                                        </fieldset>
                                        )}
                                    </>
                                ) : (
                                    <div className="Thankyou">
                                        <h2>Thank you for your feedback!</h2>
                                        <img className="ThankyouImage" src="https://portalvhds4prl9ymlwxnt8.blob.core.windows.net/confirmation-email-template-image/thank_you_gif.gif"/>
                                    </div>
                                )}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}