import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { FeedBackJS } from './Components/FeedBackJS.jsx';
import './Components/NewFeedBackCSS.css';
import { HotelFeedBackJS } from './Components/HotelFeedBackJS.jsx';
import { FeedbackProvider, useFeedbackContext } from './Components/FeedbackContext.jsx';
import axios from 'axios';
import { AlreadySubmittedJS } from './Components/AlreadySubmittedJS.jsx';

const FeedbackPage = () => {
  const location = useLocation();

  const { feedbackData, updateFeedbackData,apiData_API, updateApiData } = useFeedbackContext();
  console.log(feedbackData);
  
  const [apiData,setapiData] = useState({});
  const [flag,setflag] = useState(false);

  const extractParamsFromUrl = (search) => {
    const params = new URLSearchParams(search);
    const RowId = params.get('R');
    //const PropertyType = params.get('PropertyType');

    return {
      Row_Id: RowId ? decodeURIComponent(RowId) : null,
      //Property_Type: PropertyType ? decodeURIComponent(PropertyType) : null,
    };
  };

  const { Row_Id /*, Property_Type*/ } = extractParamsFromUrl(location.search);
  console.log('RowdID',Row_Id);
console.log(apiData_API);

useEffect(()=>{
  updateFeedbackData({
    Row_Id:Row_Id //,Property_Type:Property_Type
  })
  //window.location.href =`https://feedback.hummingbirdindia.com/?R=${Row_Id}`;
},[])

////Insert Feedback forms Table InsertFeedBackDetails 
useEffect(() => {
  if (feedbackData?.Row_Id != null){
  const fetchData = async () => {
    try {
      const response = await axios.post(
         'https://warsoftapibookingtest.staysimplyfied.com/API/FeedbackReport/InsertFeedBackDetails',
            // 'https://warsoftv3bookingapi.warsoft.in/API/FeedbackReport/InsertFeedBackDetails',
         {FeedbackEmailRowId: feedbackData.Row_Id});
      setapiData(response.data);
      updateApiData(response.data);

      if(response.data.CODE === 200){
        setflag(true);
      }
      // Run the next code line here
     console.log('Data fetched:', response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    } 
  
  };
  fetchData();
  }
 
}, [feedbackData.Row_Id]);

if(apiData.CODE === 200){
  if(apiData.Data.Msg === "AlreadyExists" || apiData.Data === "NotFound"){
    return <AlreadySubmittedJS/>
  }
  else{

    if(flag === true && apiData && apiData.Data.PropertyType === "MGH")
      {
        return <FeedBackJS/>;
      }
      else{
        return <HotelFeedBackJS/>;
      }
      // return (
  //   <div>
  //     {Property_Type === "MGH" ? <FeedBackJS/> : <HotelFeedBackJS/>}
  //   </div>
  // );
  }
}
  
};

function App() {
  return (
    <Router>
      <FeedbackProvider>
      <Routes>
        <Route path="/" element={<FeedbackPage />} />
        {/* Add more routes as needed */}
      </Routes>
      </FeedbackProvider>
    </Router>
  );
}

export default App;
